import Util from 'js/Util.js';
import crypto from 'crypto';

export default {
    setTableLocalStorageTable(tableColumns, routeName, sessionCode) {
        if (!Util.isEmpty(routeName) && !Util.isEmpty(sessionCode)) {
            const filter = tableColumns.filter((item) => !item.show).map((p) => p.property);
            const md5SessionKey = crypto.createHash('md5').update(sessionCode).digest('hex');
            const sessionLocalStorageStr = localStorage.getItem(md5SessionKey);
            const haveTableCo = (filter || []).length > 0;
            if (sessionLocalStorageStr) {
                const sessionLocalStorage = JSON.parse(sessionLocalStorageStr);
                sessionLocalStorage[routeName] = filter;
                if (sessionLocalStorage[routeName] && !haveTableCo) {
                    delete sessionLocalStorage[routeName];
                }
                localStorage.setItem(md5SessionKey, JSON.stringify(sessionLocalStorage));
            } else {
                if (haveTableCo) {
                    const _tem = {};
                    _tem[routeName] = filter;
                    localStorage.setItem(md5SessionKey, JSON.stringify(_tem));
                }
            }
        }
    },
    getTableLocalStorageTable(routeName, sessionCode) {
        if (!Util.isEmpty(routeName) && !Util.isEmpty(sessionCode)) {
            const md5SessionKey = crypto.createHash('md5').update(sessionCode).digest('hex');
            const sessionLocalStorageStr = localStorage.getItem(md5SessionKey);
            if (sessionLocalStorageStr) {
                const sessionLocalStorage = JSON.parse(sessionLocalStorageStr);
                return Object.keys(sessionLocalStorage).includes(routeName) ? sessionLocalStorage[routeName] : [];
            }
        }
        return [];
    },
};
