import Util from 'js/Util';
import LocalStorageTable from 'components/mixins/LocalStorageTable.js';

/**
 * 勾选table需要显示的列的mixin
 */
export default {
    name:
        'CheckTableShowColumn' /*该name更改需要将main.js 中executeQueryIfNeedReload方法中的mixin.name === 'CheckTableShowColumn'同时更改*/,
    data() {
        return {
            noShowStorageTableColumns: [],
            //初始化时显示所有的列标志
            initShowAllColumnFlag: true,
            //保存table中所有列的数组
            tableColumns: [],
            tableRef: 'table',
            checkTableShowColumnDialogRef: 'checkTableShowColumnDialog',
            printTableId: 'printMe',
        };
    },
    created() {
        this.noShowStorageTableColumns = LocalStorageTable.getTableLocalStorageTable(
            this.$route.name,
            this.$store.state.session.code
        );
    },
    mounted() {
        this.initColumns();
    },
    methods: {
        initColumns() {
            this.tableColumns = (this.$refs[this.tableRef].columns || [])
                .filter((e) => !Util.isEmpty(e.property))
                .map((e) => {
                    const _param = { property: e.property, label: e.label, show: true };
                    if (this.noShowStorageTableColumns.includes(e.property)) {
                        _param.show = false;
                    }
                    //默认每列都显示
                    return _param;
                });
            this.initShowAllColumnFlag = false;
        },
        showCheckTableShowColumnDialog() {
            this.initShowAllColumnFlag = false;
            this.$refs[this.checkTableShowColumnDialogRef].show(this.tableColumns);
        },
        setShowTableColumns(tableColumns = []) {
            this.tableColumns = tableColumns;
            LocalStorageTable.setTableLocalStorageTable(tableColumns, this.$route.name, this.$store.state.session.code);
        },
        showColumn(columnProperty) {
            //优先根据初始化显示列标志判断，在标志失效后，根据列中的prop字段判断是否显示
            if (this.initShowAllColumnFlag) {
                return true;
            }
            const properties = this.tableColumns.find((e) => e.property === columnProperty);
            return properties != null && properties.show;
        },
        handlePrint(printTableId) {
            if (printTableId) {
                this.printHTML(printTableId, this.$route.meta.title);
            } else {
                this.printHTML(this.printTableId, this.$route.meta.title);
            }
        },
    },
};
